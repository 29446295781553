import Vue from "vue";
import vuetify from './plugins/vuetify';
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "./registerServiceWorker";
import ReconnectingWebSocket from "reconnecting-websocket";

Vue.config.devtools = true;
Vue.config.productionTip = false;

const opts = {
  theme: { disable: true }
};

store.commit("version");

const webSocketConnectedToProxy = new ReconnectingWebSocket(`wss://${store.state.hardwareAddress}:9000/webSocketConnectedToProxy`);
webSocketConnectedToProxy.addEventListener("open", event => {
  console.log("webSocketConnectedToProxy open");
  store.dispatch("sync/onConnectedToProxy");
});
webSocketConnectedToProxy.addEventListener("message", event => {
  console.log("webSocketConnectedToProxy message: ", event.data);
  const numInstances = parseInt(event.data);
  store.commit("isSingleInstance", numInstances === 1);
});


new Vue({
  vuetify,
  router,
  el: "#app",
  store,
  render: h => h(App)
});
