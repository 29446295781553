<template>
  <v-container grid-list-xs style="height: 100%; width: 100%" fluid ma-0 pa-1>
    <v-layout row wrap fill-height>
      <v-flex xs12 md7 order-xs1>
        <v-layout row wrap>
          <v-flex id="batch-details" class="no-select" xs12>
            <v-card>
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 md10>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <div class="display-1">Batch No: {{ currentBatch ? currentBatch.batchNumber : "" }}</div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="headline">Description: {{ currentBatchDescription }}</div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="title font-weight-light" style="margin-top: 0.5em">Batch Total: {{ currentBatch ? currentBatch.totalQuantity : "" }}</div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="title font-weight-light" style="margin-top: 0.5em">Completed: {{ currentBatch ? currentBatch.completedQuantity : "" }}</div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="title font-weight-light" style="margin-top: 0.5em">
                          Next Number:
                          {{ currentBatch ? (currentBatch.completedQuantity === currentBatch.totalQuantity ? "NA" : currentBatch.completedQuantity + 1) : "" }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2 md2>
                    <dimssa-button style="height: 100%; min-height: 50px; margin-top: 2px" color="primary" :buttonState="newBatchButtonState" @onclick="onClickNewBatchButton" @onclick-disabled="onClickDisabledNewBatchButton">New Batch</dimssa-button>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex id="tag-capture" xs12>
            <v-layout row wrap fill-height>
              <v-flex xs12>
                <v-card id="tag-capture-colour-selection">
                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs2>
                        <v-layout row wrap fill-height>
                          <v-flex xs12>
                            <dimssa-button style="height: 100%" large :buttonState="noTagButtonState" @onclick="onClickNoTagButton">No Tag</dimssa-button>
                          </v-flex>
                          <v-flex xs12>
                            <dimssa-button style="height: 100%" large :buttonState="clearButtonState" @onclick="onClickClearButton">Clear</dimssa-button>
                          </v-flex>
                          <v-flex xs12>
                            <dimssa-button style="height: 100%" large :buttonState="manualButtonState" @onclick="onClickManualButton">Manual</dimssa-button>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs2>
                        <!-- <img src="/img/CowTagIcon.svg"> -->
                        <EarTag :tagColour="$store.state.tagCapture.tagColour ? $store.state.tagCapture.tagColour.Name : 'green'" />
                      </v-flex>
                      <v-flex xs6 d-flex>
                        <v-layout column>
                          <v-flex xs12 px-1>
                            <div
                              class="v-input no-spin v-text-field v-text-field--box v-text-field--enclosed v-input--is-label-active v-input--is-dirty"
                              style="height: 100%; align-self: center; vertical-align: middle; font-size: 2.5em; text-align: center"
                            >
                              <div class="v-input__control">
                                <div class="v-input__slot" style="background-color: darkslategrey; height: 75px">
                                  <div class="v-text-field__slot">
                                    <input
                                      id="eartag-input-field"
                                      ref="eartag-input-field"
                                      maxlength="20"
                                      type="text"
                                      style="text-align: center; color: white"
                                      :disabled="$store.state.tagCapture.tagCaptureState !== 'ready_for_manual_input' && $store.state.tagCapture.tagCaptureState !== 'manual_input_received'"
                                      v-on:input="onEartagInputChanged($event)"
                                      v-on:change="onEartagInputChanged($event)"
                                      v-on:keydown="onEartagInputKeyDown($event)"
                                      v-bind:value="currentEartagFull"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div style="height: 30%; align-self: center; vertical-align: middle; font-size: 1.5em">
                              {{
                                (tagTypeVisualFriendly ? tagTypeVisualFriendly : "") +
                                ($store.state.tagCapture.tagColour ? ` - ${$store.state.tagCapture.tagColour.Name.slice(0, 1).toUpperCase()}${$store.state.tagCapture.tagColour.Name.slice(1)}` : "") +
                                ($store.getters["getCurrentTagFeedlot"] ? ` - ${$store.getters["getCurrentTagFeedlot"].Name}` : "")
                              }}
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs2 d-flex align-center>
                        <div v-if="isManualTagEntry" class="text-xs-center">Manual Entry</div>
                        <dimssa-button v-else style="height: 100%; width: 100%" :buttonState="scanButtonState" @onclick="onClickScanButton" @onclick-disabled="onClickDisabledScanButton">Scan</dimssa-button>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex id="scale-buttons-graph" xs12 fill-height>
            <v-layout row fill-height>
              <v-flex xs6>
                <v-card id="scale-graph" v-if="$store.state.massCaptureEnabled" class="no-select">
                  <v-card-text>
                    <svg id="scale-graph-svg" ref="scale-graph-svg" style="height: 200px" />
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs6>
                <v-layout row wrap fill-height>
                  <v-flex xs12>
                    <v-card id="scale-mass-and-capture-button" v-if="$store.state.massCaptureEnabled" class="no-select" height="100%">
                      <v-card-text style="height: 100%">
                        <v-layout row fill-height>
                          <v-flex xs6>
                            <v-layout row fill-height>
                              <v-flex id="scale-value-text" xs8 style="font-size: 3em" d-flex align-center>
                                <div class="text-xs-center">{{ Math.round(currentMass) }}</div>
                              </v-flex>
                              <v-flex xs4>
                                <v-layout column fill-height>
                                  <v-flex id="scale-activity-icon" d-flex align-center>
                                    <svg viewBox="0 0 100 100" style="width: 75%; height: 75%; align-self: center">
                                      <circle cx="50" cy="50" r="50" id="scaleActivityCircle" ref="scaleActivityCircle" fill="lime" />
                                    </svg>
                                  </v-flex>
                                  <v-flex d-flex align-center style="font-size: 2em">
                                    <div class="text-xs-center">kg</div>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs6 d-flex align-center>
                            <dimssa-button :buttonState="captureButtonState" @onclick="onClickCaptureButton" @onclick-disabled="onClickDisabledCaptureButton" style="height: 100%; width: 100%; padding-left: 23px">Capture</dimssa-button>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex xs6 />
                  <v-flex xs6 align-center>
                    <v-card id="save-button" height="100%">
                      <v-card-text style="height: 100%" pa-0>
                        <dimssa-button style="text-align: center; height: 100%" :buttonState="saveButtonState" @onclick="onClickSaveButton" @onclick-disabled="onClickDisabledSaveButton">Save</dimssa-button>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md5 order-xs3 order-md2 pa-0>
        <v-layout>
          <v-flex id="slaughtered-animals-table" style="overflow: auto" :style="$vuetify.breakpoint.mdAndUp ? 'height: 600px' : 'height: auto;'">
            <v-data-table :headers="animalTableHeaders" :items="animalTableItems" hide-default-footer>
              <template slot="item" slot-scope="props">
                <tr>
                  <td class="text-xs-center">{{ props.item.number }}</td>
                  <td style="padding: 0 12px">
                    <EarTag style="width: 50%; height: 32px; float: left" :tagColour="props.item.colour" />
                    <div style="display: flex; align-items: center; width: 50%; float: right; height: 32px">{{ props.item.eartagId }}</div>
                  </td>
                  <td class="text-xs-center">
                    <div>{{ props.item.feedlot }}</div>
                  </td>
                  <td v-if="$store.state.massCaptureEnabled" class="text-xs-center">{{ props.item.liveMass }}</td>
                  <td>
                    <v-icon v-if="props.item.status === 'receivedByServer'" color="lime" style="display: flex; align-items: center; width: 100%">done</v-icon>
                    <v-icon v-else-if="props.item.status === 'receivedBy3rdParty'" color="lime" style="display: flex; align-items: center; width: 100%">done_all</v-icon>
                    <v-icon v-else-if="props.item.status === 'failedToSend'" size="24" color="red" style="display: flex; align-items: center; width: 100%">clear</v-icon>
                    <v-progress-circular v-else-if="props.item.status === 'sending'" size="24" indeterminate style="margin: 1px; display: flex; align-items: center; width: 100%" color="blue" />
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 order-xs2 order-md3>
        <v-layout row wrap>
          <v-flex md2 xs6>
            <v-card height="100%">
              <v-card-text style="padding: 4px">
                <div id="company-site-sgln-status" style="border-radius: 10px; display: block; align-items: center">
                  <div>Company: {{ $store.getters.companyName }}</div>
                  <div>Site: {{ $store.getters.siteName }}</div>
                  <div>GCP: {{ $store.state.sgln ? $store.state.sgln.split(".")[1] : "NA" }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md2 xs6>
            <v-card height="100%">
              <v-card-text style="padding: 4px">
                <v-layout row wrap>
                  <v-flex xs6>Hardware</v-flex>
                  <v-flex xs6 v-on:click="onClickHardwareInStatusBar">
                    <v-icon v-if="diagHardwareStatus === 'connected'" color="lime" style="display: flex; align-items: center; width: 100%">done</v-icon>
                    <v-icon v-else-if="diagHardwareStatus === 'disconnected'" size="24" color="red" style="display: flex; align-items: center; width: 100%">clear</v-icon>
                    <v-progress-circular v-else-if="diagHardwareStatus === 'connecting'" size="22" indeterminate style="margin: 1px; display: flex; align-items: center; width: 100%" color="blue" />
                  </v-flex>
                  <v-flex xs6>3rd Party</v-flex>
                  <v-flex xs6 v-on:click="diag3rdPartyStatus = diag3rdPartyStatus === 'connecting' ? 'disconnected' : 'connecting'">
                    <v-icon v-if="diag3rdPartyStatus === 'connected'" color="lime" style="display: flex; align-items: center; width: 100%">done</v-icon>
                    <v-icon v-else-if="diag3rdPartyStatus === 'disconnected'" size="24" color="red" style="display: flex; align-items: center; width: 100%">clear</v-icon>
                    <v-progress-circular v-else-if="diag3rdPartyStatus === 'connecting'" size="22" indeterminate style="margin: 1px; display: flex; align-items: center; width: 100%" color="blue" />
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md3 xs12>
            <v-card height="100%">
              <v-card-text style="padding: 4px">
                <v-layout row wrap class="no-select">
                  <v-flex xs2 v-if="$store.state.massCaptureEnabled">Scale</v-flex>
                  <v-flex xs2 v-if="$store.state.massCaptureEnabled">
                    <v-icon v-if="diagScaleStatus === 'connected'" color="lime" style="display: flex; align-items: center; width: 100%">done</v-icon>
                    <v-icon v-else-if="diagScaleStatus === 'disconnected'" size="24" color="red" style="display: flex; align-items: center; width: 100%">clear</v-icon>
                    <v-progress-circular v-else-if="diagScaleStatus === 'connecting'" size="22" indeterminate style="margin: 1px; display: flex; align-items: center; width: 100%" color="blue" />
                  </v-flex>
                  <v-flex xs1 d-flex align-center v-if="$store.state.massCaptureEnabled">
                    <svg style="height: 20px" viewBox="0 0 10 10">
                      <circle cx="5" cy="5" r="5" id="rawScaleActivityCircle" ref="rawScaleActivityCircle" fill="lime" />
                    </svg>
                  </v-flex>
                  <v-flex xs7 v-if="$store.state.massCaptureEnabled">{{ rawMass }} kg</v-flex>
                  <v-flex xs2 @click="onClickRfidInStatusBar">RFID</v-flex>
                  <v-flex xs2 @click="onClickRfidInStatusBar">
                    <v-icon v-if="diagRfidStatus === 'connected'" color="lime" style="display: flex; align-items: center; width: 100%">done</v-icon>
                    <v-icon v-else-if="diagRfidStatus === 'disconnected'" size="24" color="red" style="display: flex; align-items: center; width: 100%">clear</v-icon>
                    <v-progress-circular v-else-if="diagRfidStatus === 'connecting'" size="22" indeterminate style="margin: 1px; display: flex; align-items: center; width: 100%" color="blue" />
                  </v-flex>
                  <v-flex xs8 @click="onClickRfidInStatusBar">{{ recentDimssaScan }}</v-flex>
                  <v-flex xs2 v-if="allflexEnabled">Allflex</v-flex>
                  <v-flex xs2 v-if="allflexEnabled">
                    <v-icon v-if="diagAllflexStatus === 'connected'" color="lime" style="display: flex; align-items: center; width: 100%">done</v-icon>
                    <v-icon v-else-if="diagAllflexStatus === 'disconnected'" size="24" color="red" style="display: flex; align-items: center; width: 100%">clear</v-icon>
                    <v-progress-circular v-else-if="diagAllflexStatus === 'connecting'" size="22" indeterminate style="margin: 1px; display: flex; align-items: center; width: 100%" color="blue" />
                  </v-flex>
                  <v-flex xs8 v-if="allflexEnabled">{{ recentAllflexScan }}</v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md5 xs12>
            <v-card height="100%">
              <v-card-text style="padding: 4px">{{ statusMessage }}</v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-dialog v-model="isNewBatchDialogVisible" persistent max-width="800" scrollable>
      <v-card v-if="batchListFetchState === 'busy'">
        <v-card-title class="headline">Busy..</v-card-title>
        <v-card-text>
          <v-progress-circular size="24" indeterminate style="margin: 1px; display: flex; align-items: center; width: 100%" color="blue" />
        </v-card-text>
      </v-card>
      <v-card v-else-if="batchListFetchState === 'succeeded'">
        <v-card-title class="headline">
          <div v-if="candidateBatch === null || candidateBatch.batchNumber === null">Select Batch</div>
          <div v-else-if="candidateBatch !== null && candidateBatch.batchNumber !== null">Select Batch ({{ candidateBatch ? candidateBatch.batchNumber : "" }})</div>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="batchTableHeaders" :items="fetchedBatches" hide-default-footer items-per-page="40">
            <template slot="item" slot-scope="props">
              <tr v-on:click="onBatchSelected(props.item)" :class="candidateBatch && props.item.BatchNumber === candidateBatch.batchNumber ? 'grey darken-1' : ''">
                <td class="text-xs-center">{{ props.item.BatchNumber }}</td>
                <td class="text-xs-center">{{ props.item.Description }}</td>
                <td class="text-xs-center">{{ props.item.TotalQty }}</td>
                <td class="text-xs-center">{{ props.item.ComplQty }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="onBatchSelectionConfirmed">OK</v-btn>
          <v-btn color="green darken-1" @click="onBatchSelectionCancelled">Cancel</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="batchListFetchState === 'failed'">
        <v-card-title class="headline">Error</v-card-title>
        <v-card-text>Failed to fetch batches from server</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="isNewBatchDialogVisible = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isNoTagDialogVisible" persistent max-width="800">
      <v-card>
        <v-card-title class="headline">Warning</v-card-title>
        <v-card-text>Are you sure there is no tag? Type "No Tag" before pressing Yes.</v-card-text>
        <div class="v-input no-spin v-text-field v-text-field--box v-text-field--enclosed v-input--is-label-active v-input--is-dirty theme--dark" style="height: 100%; align-self: center; vertical-align: middle; font-size: 2.5em; text-align: center">
          <div class="v-input__control">
            <div class="v-input__slot">
              <div class="v-text-field__slot">
                <input id="no-tag-input-field" ref="no-tag-input-field" maxlength="20" type="text" style="text-align: center" v-model="noTagForcedInput" onpaste="return false;" />
              </div>
            </div>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="onNoTagConfirmed" :disabled="noTagForcedInput !== 'No Tag'">Yes</v-btn>
          <v-btn color="green darken-1" @click="onNoTagCancelled">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isClearTagDialogVisible" persistent max-width="800">
      <v-card>
        <v-card-title class="headline">Warning</v-card-title>
        <v-card-text>Are you sure that you want to clear the tag?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" @click="onClearTagConfirmed">Yes</v-btn>
          <v-btn color="green darken-1" @click="onClearTagCancelled">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="okPopupVisible" persistent max-width="800">
      <v-card>
        <v-card-title class="headline">Warning</v-card-title>
        <v-card-text>{{ okPopupMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="okPopupVisible = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isManualDialogVisible" persistent max-width="800">
      <v-card>
        <v-card-title class="headline">Manual Entry</v-card-title>
        <v-card-text>
          <v-item-group ref="manual-tag-item-group">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs4 md4>
                  <v-item>
                    <v-card
                      slot-scope="{ active, toggle }"
                      :color="active ? 'primary' : ''"
                      class="d-flex align-center"
                      dark
                      height="25vh"
                      @click="
                        toggle();
                        onClickManualTagTypeItem('dimssa-manual', !active);
                      "
                    >
                      <v-card-text>
                        <v-img contain height="130" src="../img/Tag_icon-removebg.png"></v-img>
                        <div class="display-1 text-center">Gigalot</div></v-card-text
                      >
                    </v-card>
                  </v-item>
                </v-flex>
                <v-flex xs4 md4>
                  <v-item>
                    <v-card
                      slot-scope="{ active, toggle }"
                      :color="active ? 'primary' : ''"
                      class="d-flex align-center"
                      dark
                      height="25vh"
                      @click="
                        toggle();
                        onClickManualTagTypeItem('plain', !active);
                      "
                    >
                      <v-card-text
                        ><v-img contain height="130" src="../img/Plain_eartag-removebg.png"></v-img>
                        <div class="display-1 text-center">Plain</div></v-card-text
                      >
                    </v-card>
                  </v-item>
                </v-flex>
                <v-flex xs4 md4>
                  <v-item>
                    <v-card
                      slot-scope="{ active, toggle }"
                      :color="active ? 'primary' : ''"
                      class="d-flex align-center"
                      dark
                      height="25vh"
                      @click="
                        toggle();
                        onClickManualTagTypeItem('allflex-manual', !active);
                      "
                    >
                      <v-card-text>
                        <v-img contain height="130" src="../img/Allflex_tag-removebg.png"></v-img>
                        <div class="display-1 text-center">Allflex</div></v-card-text
                      >
                    </v-card>
                  </v-item>
                </v-flex>
              </v-layout>
            </v-container>
          </v-item-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="onManualTagConfirmed" :disabled="$store.state.tagCapture.tagType === null">OK</v-btn>
          <v-btn color="green darken-1" @click="onManualTagCancelled">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isColourSelectionDialogVisible" persistent max-width="800" scrollable>
      <v-card>
        <v-card-title class="headline">Select Colour</v-card-title>
        <v-card-text>
          <v-item-group ref="tag-colour-item-group">
            <v-container grid-list-xs>
              <v-layout wrap>
                <v-flex xs3 md2 v-for="colour in $store.state.colourInfo" v-bind:key="colour.Number">
                  <v-item>
                    <v-card
                      slot-scope="{ active, toggle }"
                      :style="active ? 'border: 1px solid lime' : ''"
                      style="display: flex; flex-direction: column"
                      :elevation="active ? 10 : 1"
                      dark
                      height="200"
                      @click="
                        toggle();
                        onClickTagColourItem(colour, !active);
                      "
                    >
                      <div :style="`color: ${colour.Hex}`" class="text-xs-center" style="height: 50%; font-size: 2vmax">
                        {{ colour.Name[0].toUpperCase() + colour.Name.slice(1) }}
                      </div>
                      <div style="width 100%; height: 50%; margin: 5px" :style="`background-color: ${colour.Hex}`"></div>
                    </v-card>
                  </v-item>
                </v-flex>
              </v-layout>
            </v-container>
          </v-item-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="onTagColourConfirmed" :disabled="$store.state.tagCapture.tagColour === null">OK</v-btn>
          <v-btn color="green darken-1" @click="onTagColourCancelled">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isSettingsDialogVisible" persistent max-width="800">
      <v-card>
        <v-card-title class="headline">Settings</v-card-title>
        <v-card-text>
          <v-input :messages="['Hardware address']" prepend-icon="home">
            <input id="hardware-address-input-field" ref="hardware-address-input-field" @input="onHardwareAddressInputChanged($event)" />
          </v-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="onClickOkSettingDialog">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-content v-else style="width: 100%; height: 100%">
      <v-card>
        <v-card-text>Rotate screen to landscape.</v-card-text>
      </v-card>
    </v-content> -->
  </v-container>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
import lodash from "lodash";
import uuidv4 from "uuid/v4";

import EarTag from "../components/shared/EarTag";
import DimssaButton from "../components/shared/dimssa-button.vue";
import DimssaTable from "../components/shared/dimssa-table.vue";

import { initializeChart, drawChart } from "../scale-graph";
import { Animal } from "../animal";
import { Batch } from "../batch";
import store from "../store/store";
import ColourInfo from "../colour-info";

import { configData } from "../config";

import { config } from "@vue/test-utils";
import { sep } from "path";

import ReconnectingWebSocket from "reconnecting-websocket";
import { scaleTime } from "d3";

function sigmoid(t) {
  return 1.0 / (1.0 + Math.pow(Math.E, -t));
}

let timer = 0.0;
let stopAddingMass = false; //used with the sigmoid fake mass

let vmApp = {
  name: "App",
  store,
  components: {
    EarTag,
    DimssaButton,
    DimssaTable,
  },
  data() {
    return {
      noTagForcedInput: "",
      isSettingsDialogVisible: false,
      isBusySaving: false,
      okPopupVisible: false,
      okPopupMessage: "",
      //isManualTagEntry: false,
      unsentAnimalRecords: new Array(),
      candidateBatch: null, //the selected batch in list of batches, selection still needs to be confirmed
      fetchedBatches: [],
      batchListFetchState: "notBusy", //"notBusy" | "busy" | "succeeded" | "failed"
      scanTagTimeout: 5, //seconds
      captureMassTimeout: 7.5, //seconds
      captureMassState: "notCapturing", //"notCapturing" | "capturing" | "captured" | "failed"
      captureMassCancelTimerDebounce: undefined,
      capturedMass: 0,
      currentMass: 0,
      rawMass: 0,
      //currentEartag: "",
      currentEartagFull: "",
      recentAllflexScan: "",
      recentAllflexScanDebounced: null,
      recentDimssaScan: "",
      recentDimssaScanDebounced: null,
      hardwareMultiClickedDebounced: null,
      hardwareMultiClickAmount: 0,
      hardwareMultiClickTotal: 2,
      massQueue: new Array(),
      captureMassQueue: new Array(),
      scaleActivityToggle: false,
      statusMessage: "",
      diagHardwareStatus: "disconnected", //"connecting" | "connected" | "disconnected"
      diag3rdPartyStatus: "disconnected",
      diagCloudStatus: "disconnected",
      diagScaleStatus: "disconnected",
      diagRfidStatus: "disconnected",
      diagAllflexStatus: "disconnected",
      batchTableHeaders: [
        {
          text: "Number",
          align: "center",
          sortable: false,
          value: "string",
        },
        {
          text: "Description",
          align: "center",
          sortable: false,
          value: "string",
        },
        {
          text: "Total",
          align: "center",
          sortable: false,
          value: "number",
        },
        {
          text: "Completed",
          align: "center",
          sortable: false,
          value: "number",
        },
      ],
      windowSize: {
        x: 0,
        y: 0,
      },
      isNewBatchDialogVisible: false,
      isNoTagDialogVisible: false,
      isClearTagDialogVisible: false,
    };
  },
  methods: {
    onClickOkSettingDialog: function () {
      this.isSettingsDialogVisible = false;
      this.$store.commit("SET_HARDWARE_ADDRESS", this.$refs["hardware-address-input-field"].value);
    },
    getGcpFromSgtin: function (sgtin) {
      return sgtin ? sgtin.split(".")[1] : null;
    },
    getColourModeFromSgtin: function (sgtin) {
      if (!this.$store.state.feedlotInfo) return null;
      let gcp = this.getGcpFromSgtin(sgtin);
      if (!gcp) return null;

      let feedlot = lodash.find(this.$store.state.feedlotInfo, (f) => {
        return f.GCP === gcp;
      });

      return feedlot ? feedlot.ColorMode : null;
    },
    getFeedlotNameFromSgtin(sgtin) {
      if (!this.$store.state.feedlotInfo) return null;
      let gcp = this.getGcpFromSgtin(sgtin);
      if (!gcp) return null;

      let feedlot = lodash.find(this.$store.state.feedlotInfo, (f) => {
        return f.GCP === gcp;
      });

      return feedlot ? feedlot.Name : null;
    },
    getVisualFriendlyTagType(tagType) {
      if (!tagType) {
        return null;
      }
      switch (tagType) {
        case "no-tag": {
          return "No tag";
        }
        case "plain": {
          return "Plain";
        }
        case "allflex": {
          return "Allflex";
        }
        case "allflex-manual": {
          return "Allflex Manual";
        }
        case "dimssa": {
          return "DIMSSA";
        }
        case "dimssa-manual": {
          return "DIMSSA Manual";
        }
      }
    },
    getVisualFriendlyTag: function (tagId, tagType) {
      if (tagType === "dimssa") {
        let colourMode = this.getColourModeFromSgtin(tagId);
        if (colourMode === 1)
          //"single-colour"
          return tagId[36] + tagId.slice(-5);
        else if (colourMode === 0)
          //"multi-colour"
          return tagId.slice(-5);
        //else if multi colour mode then just take last 5;
      } else if (tagType === "allflex") {
        return tagId.slice(-12);
      } else if (tagType === "dimssa-manual") {
        //return tagId.slice(1);
        return tagId;
      } else {
        return tagId;
      }
    },
    onClickHardwareInStatusBar: function () {
      this.diagHardwareStatus = this.diagHardwareStatus === "connecting" ? "disconnected" : "connecting";

      this.hardwareMultiClickAmount++;

      if (this.hardwareMultiClickAmount >= this.hardwareMultiClickTotal) {
        if (!this.isSettingsDialogVisible) {
          this.$refs["hardware-address-input-field"].value = this.$store.state.hardwareAddress;
          this.isSettingsDialogVisible = true;
          console.log("open hardware settings");
        }
      }

      if (this.hardwareMultiClickedDebounced) {
        this.hardwareMultiClickedDebounced.cancel();
      }
      this.hardwareMultiClickedDebounced = lodash.debounce(() => {
        this.hardwareMultiClickAmount = 0;
      }, 10000);
      this.hardwareMultiClickedDebounced();

      //detect multiple taps then open settings dialog
      //start a timer that clears
    },
    onClickManualTagTypeItem: function (tagType, isSelected) {
      console.log("onClickManualTagTypeItem: " + tagType + " " + isSelected);
      if (isSelected) {
        this.$store.commit("tagCapture/SET_TAG_TYPE", tagType);
      } else {
        this.$store.commit("tagCapture/SET_TAG_TYPE", null);
      }
    },
    onClickTagColourItem: function (tagColour, isSelected) {
      if (isSelected) {
        this.$store.commit("tagCapture/SET_TAG_COLOUR", tagColour);
      } else {
        this.$store.commit("tagCapture/SET_TAG_COLOUR", null);
      }
    },
    onResize: function () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      initializeChart();
    },
    onClickRfidInStatusBar: function (event) {
      //console.log("RFID");
      if (this.recentDimssaScanDebounced) {
        this.recentDimssaScanDebounced.cancel();
      }

      this.recentDimssaScan = "Checking...";

      axios.defaults.timeout = 10000;
      //axios.get("https://reqres.in/api/users/2")
      //TODO: Environment check for http and https
      axios
        .get("https://" + this.server.address + ":" + this.server.portHttp + configData.restApiCalls.rfidReaderScan)
        .then((response) => {
          if (response.data.startsWith("ERROR")) {
            this.recentDimssaScan = response.data;
          } else {
            this.recentDimssaScan = response.data.slice(-6);
          }
        })
        .catch((error) => {
          this.recentDimssaScan = "ERROR";
        })
        .finally(() => {
          this.recentDimssaScanDebounced = lodash.debounce(() => {
            this.recentDimssaScan = "";
          }, 2000);
          this.recentDimssaScanDebounced();
        });
    },
    onClickScanButton: function (event) {
      this.statusMessage = "";
      if (this.$store.state.tagCapture.tagCaptureState !== "empty_tag") {
        this.statusMessage = "Tag already captured: you must first Clear or Save";
        return;
      }

      this.$store.commit("tagCapture/SET_STATE", "scanning_dimssa_tag");

      let app = this;
      axios.defaults.timeout = 10000;
      //TODO: Environment check for http and https
      axios
        .get("https://" + app.server.address + ":" + app.server.portHttp + configData.restApiCalls.rfidReaderScan)
        .then((response) => {
          //lamda function has to be used here, using function() syntax results in a different 'this'
          if (response.data.startsWith("ERROR")) {
            app.statusMessage = response.data;
            this.$store.commit("tagCapture/SET_STATE", "empty_tag");
            this.$store.commit("tagCapture/FLAG_ERROR", response.data);
          } else {
            app.recentDimssaScan = this.getVisualFriendlyTag(response.data, "dimssa"); //response.data.slice(-6);
            if (app.recentDimssaScanDebounced) {
              app.recentDimssaScanDebounced.cancel();
            }
            app.recentDimssaScanDebounced = lodash.debounce(() => {
              app.recentDimssaScan = "";
            }, 2000);
            app.recentDimssaScanDebounced();
            //TODO: Environment check for http and https
            axios
              .get("https://" + app.server.address + ":" + app.server.portHttp + configData.restApiCalls.isSlaughtered + "/" + response.data)
              .then((response2) => {
                console.log("response: " + response);
                let isSlaughtered = response2.data;
                if (!isSlaughtered) {
                  this.$store.commit("tagCapture/SET_TAG_ID", response.data);
                  this.$store.commit("tagCapture/SET_TAG_TYPE", "dimssa");
                  this.$store.commit("tagCapture/SET_STATE", "success_dimssa_tag_scanned");
                  //this.currentEartagFull = this.$store.state.tagCapture.tagId;
                  this.currentEartagFull = this.getVisualFriendlyTag(this.$store.state.tagCapture.tagId, this.$store.state.tagCapture.tagType);

                  this.$store.commit("tagCapture/SET_TAG_COLOUR", this.$store.getters["getTagColour"](this.$store.state.tagCapture.tagId));
                  app.statusMessage = "";
                  //app.scanTagState = "scanned";
                } else {
                  app.okPopupVisible = true;
                  let s = `Error: duplicated detected ${this.getVisualFriendlyTag(response.data, "dimssa")}`;
                  app.okPopupMessage = s;
                  app.statusMessage = s;
                  //app.scanTagState = "failed";
                  this.$store.commit("tagCapture/SET_STATE", "empty_tag");
                  this.$store.commit("tagCapture/FLAG_ERROR", s);
                }
              })
              .catch((error) => {
                console.log("error: " + response);
                let s = "Error checking duplicate";
                app.statusMessage = s;
                //app.scanTagState = "failed";
                this.$store.commit("tagCapture/SET_STATE", "empty_tag");
                this.$store.commit("tagCapture/FLAG_ERROR", s);
              });
          }
        })
        .catch((error) => {
          let s = "Error: " + (error.response !== undefined ? error.response.data.Message : error.message);
          this.statusMessage = s;

          //this.scanTagState = "failed";
          this.$store.commit("tagCapture/SET_STATE", "empty_tag");
          this.$store.commit("tagCapture/FLAG_ERROR", s);
        });
    },
    onClickDisabledScanButton: function () {
      this.statusMessage = "Scan Button Disabled";
    },
    onClickCaptureButton: function () {
      this.statusMessage = "";
      this.captureMassState = "capturing";
      this.captureMassQueue = new Array();
      this.captureMassCancelTimerDebounce = lodash.debounce(() => {
        if (this.captureMassState === "capturing") {
          this.captureMassState = "failed";
          this.statusMessage = `Mass capture timed out after ${this.captureMassTimeout} seconds.`;
        }
      }, this.captureMassTimeout * 1000);
      this.captureMassCancelTimerDebounce();
    },
    onClickDisabledCaptureButton: function () {
      this.statusMessage = "Capture Button Disabled";
    },
    onClickSaveButton: async function (event) {
      this.statusMessage = "";
      //add animal data to table
      //set icon to progress
      //alert(uuidv4())

      // let animalRecord = {
      //   batchId: "123456",
      //   animalUid: uuidv4(),
      //   numberInBatch: 0,
      //   eartagId: "123456",
      //   urn: "",
      //   liveMass: 888,
      // }
      if (this.isBusySaving) {
        console.log("avoiding saving more than once");
        return;
      }

      this.isBusySaving = true;

      let animalRecord = new Animal();
      animalRecord.timestamp = new Date().getTime();
      animalRecord.tagColourNumber = this.$store.state.tagCapture.tagType === "dimssa-manual" ? this.$store.state.tagCapture.tagColour.Number : null;
      animalRecord.tagType = this.$store.state.tagCapture.tagType;
      animalRecord.tagId = this.$store.state.tagCapture.tagId;
      animalRecord.feedlot = "feedlot";
      animalRecord.mass = this.massCaptureEnabled && this.captureMassState === "captured" ? this.currentMass : 0;
      animalRecord.uploadStatus = "failedToSend";
      animalRecord.numberInQueue = this.currentBatch.completedQuantity + 1;
      this.statusMessage = "";

      this.unsentAnimalRecords[animalRecord.animalUuid] = animalRecord;
      //this.currentBatch.animals.push(animalRecord);
      await this.$store.dispatch("saveAnimal", animalRecord);

      let isFinished = this.$store.state.currentBatch.completedQuantity === this.$store.state.currentBatch.totalQuantity;
      let tagType = this.$store.state.tagCapture.tagType;

      if (isFinished) {
        this.okPopupVisible = true;
        this.okPopupMessage = "Batch Complete, select new batch";
      }

      //clear captured tag and mass
      this.$store.commit("tagCapture/SET_TAG_ID", "");
      this.currentEartagFull = this.$store.state.tagCapture.tagId;
      if (!isFinished && tagType === "plain") {
        this.$store.commit("tagCapture/SET_STATE", "ready_for_manual_input");
        this.$refs["eartag-input-field"].focus();
      } else if (isFinished || tagType !== "plain") {
        this.$store.commit("tagCapture/SET_STATE", "empty_tag");
      }
      //this.scanTagState = "notScanning";

      this.captureMassState = "notCapturing";
      //this.isManualTagEntry = false;

      // let o = {
      //   BatchNo: "Test Batch",
      //   AnimalNo: 1,
      //   Eartag: "W00123",
      //   Eartag2: null,
      //   RfidTag: "",
      //   LiveMass: 334,
      //   HookMass: 0,
      //   GrossMass: 334,
      //   Feedlot: null,
      //   Timestamp: "/Date(1543558980000+0200)/"
      // }

      this.isBusySaving = false;
    },
    onClickDisabledSaveButton: function () {
      this.statusMessage = "Save button disabled: ";

      let tagCaptureState = this.$store.state.tagCapture.tagCaptureState;
      let tagCaptureReadyToSave = ["success_dimssa_tag_scanned", "allflex_tag_scanned", "no_tag", "manual_input_received"].includes(tagCaptureState);

      if (!tagCaptureReadyToSave) {
        this.statusMessage += "tag must first be captured";
      } else if (this.massCaptureEnabled && this.captureMassState !== "captured") {
        this.statusMessage += "live mass must first be captured";
      } else if (!this.currentBatch) {
        this.statusMessage += "batch must first be selected";
      }
    },
    onBatchSelected: function (batch) {
      this.candidateBatch = new Batch();
      this.candidateBatch.batchNumber = batch.BatchNumber;
      this.candidateBatch.description = batch.Description;
      this.candidateBatch.totalQuantity = batch.TotalQty;
      this.candidateBatch.completedQuantity = batch.ComplQty;
      this.candidateBatch.animals = lodash.map(batch.Animals, (o) => {
        let a = new Animal();
        a.animalUuid = o.Uuid;
        a.mass = o.LiveMass;
        a.tagId = o.TagType === "dimssa" ? o.RfidTag : o.Eartag;

        a.tagType = o.TagType;

        a.numberInQueue = o.AnimalNo;
        a.uploadStatus = "receivedByServer";
        return a;
        //TODO: timestamp if needed
        // o.Uuid = animal.animalUuid,
        // o.BatchNo = state.currentBatch.batchNumber,
        // o.AnimalNo = animal.numberInQueue,
        // o.Eartag = animal.tagId,
        // o.LiveMass = animal.mass,
        // o.GrossMass = animal.mass,
        // o.Timestamp = "/Date("+animal.timestamp+"+0200)/"
      });
    },
    onBatchSelectionConfirmed: async function () {
      this.isNewBatchDialogVisible = false;
      //this.currentBatch = this.candidateBatch;
      await this.$store.dispatch("setCurrentBatch", this.candidateBatch);
      this.currentEartagFull = this.$store.state.tagCapture.tagId;
    },
    onBatchSelectionCancelled: function () {
      this.isNewBatchDialogVisible = false;
      this.candidateBatch = null;
    },
    onManualTagConfirmed: function () {
      //if dimssa-manual chosen then ask user to select colour
      //else do the usual
      this.$refs["manual-tag-item-group"].internalLazyValue = undefined;
      let tagType = this.$store.state.tagCapture.tagType;
      if (tagType === "dimssa-manual") {
        this.$store.commit("tagCapture/SET_STATE", "tag_colour_selection");
      } else {
        this.$store.commit("tagCapture/SET_STATE", "ready_for_manual_input");
        this.$refs["eartag-input-field"].disabled = false;
        this.$refs["eartag-input-field"].focus();
      }
    },
    onManualTagCancelled: function () {
      this.$store.commit("tagCapture/SET_STATE", "empty_tag");
      this.$refs["manual-tag-item-group"].internalLazyValue = undefined;
    },
    onTagColourConfirmed: function () {
      this.$refs["tag-colour-item-group"].internalLazyValue = undefined;
      this.$store.commit("tagCapture/SET_STATE", "ready_for_manual_input");
      this.$refs["eartag-input-field"].disabled = false;
      this.$refs["eartag-input-field"].focus();
    },
    onTagColourCancelled: function () {
      this.$refs["tag-colour-item-group"].internalLazyValue = undefined;
      this.$store.commit("tagCapture/SET_STATE", "empty_tag");
    },
    onClearTagConfirmed: function () {
      this.isClearTagDialogVisible = false;
      this.$store.commit("tagCapture/SET_TAG_ID", "");
      this.currentEartagFull = this.$store.state.tagCapture.tagId;
      this.$store.commit("tagCapture/SET_STATE", "empty_tag");
    },
    onClearTagCancelled: function () {
      this.isClearTagDialogVisible = false;
    },
    onClickNoTagButton: function (event) {
      if (this.$store.state.config.mustNoTagBeTyped) {
        //"No Tag" must be typed to confirm no tag
        this.isNoTagDialogVisible = true;
        this.noTagForcedInput = "";
        lodash.debounce(() => {
          this.$refs["no-tag-input-field"].focus();
        }, 500)();
      } else {
        //Here we confirm no tag only with the no tag button, no typing is wanted
        this.onNoTagConfirmed();
      }
    },
    onClickClearButton: function (event) {
      this.isClearTagDialogVisible = true;
    },
    onClickManualButton: function (event) {
      this.$store.commit("tagCapture/SET_STATE", "tag_type_selection");
      console.log("onClickManualButton");
    },
    onNoTagConfirmed: function () {
      //this.checkReceived3rdParty();
      //Popup are you sure?
      //
      this.isNoTagDialogVisible = false;
      this.$store.commit("tagCapture/SET_STATE", "no_tag");
      this.$store.commit("tagCapture/SET_TAG_TYPE", "no-tag");
      let numNoTags;
      if (this.$store.state.currentBatch) {
        let savedAnimals = this.$store.state.currentBatch.animals;
        numNoTags = savedAnimals.filter((animal) => animal.tagType === "no-tag").length;
      } else {
        numNoTags = 0;
      }
      this.$store.commit("tagCapture/SET_TAG_ID", `NO TAG ${numNoTags + 1}`);
      this.currentEartagFull = this.$store.state.tagCapture.tagId;
      this.statusMessage = "NO TAG";
      //this.scanTagState = "scanned";
    },
    onNoTagCancelled: function () {
      this.isNoTagDialogVisible = false;
    },
    onClickNewBatchButton: function (tagType) {
      //this.selectedTagType = tagType;
      this.isNewBatchDialogVisible = true;
      this.batchListFetchState = "busy";
      this.fetchedBatches = [];
      console.log("https://" + this.server.address + ":" + this.server.portHttp + configData.restApiCalls.getBatches);
      //TODO: do environment chech for http and https
      axios
        .get("https://" + this.server.address + ":" + this.server.portHttp + configData.restApiCalls.getBatches, { timeout: 20000 })
        .then((response) => {
          //this.selectedTagType = tagType;
          //this.$store.commit("SET_SELECTED_TAG_TYPE", tagType);
          this.fetchedBatches = response.data.filter((i) => i !== null);
          this.batchListFetchState = "succeeded";
          if (this.fetchedBatches.length > 0) {
            this.batchListFetchState = "succeeded";
          } else {
            console.log("empty list of batches returned");
            this.statusMessage = "empty list of batches returned";
            this.batchListFetchState = "failed";
          }
        })
        .catch((error) => {
          this.batchListFetchState = "failed";
        });
    },
    onClickDisabledNewBatchButton: function () {
      if (this.newBatchButtonState === "disabled") {
        this.statusMessage = "Can not start new batch until all animals on the list have been uploaded";
      }
    },
    onHardwareAddressInputChanged: function (event) {
      let hardwareAddress = this.$refs["hardware-address-input-field"].value;
      console.log(hardwareAddress);
    },
    onEartagInputChanged: function (event) {
      console.log("Hello?");
      let tagCaptureState = this.$store.state.tagCapture.tagCaptureState;
      if (tagCaptureState === "ready_for_manual_input" || tagCaptureState === "manual_input_received") {
        //not sure why but this works when entering input to a text box that has a bound value
        //without this code you will see a character entered into the text box and then quickly disappear
        if (this.$store.state.tagCapture.tagType === "dimssa-manual") {
          this.currentEartagFull = this.$refs["eartag-input-field"].value.replace(/\D/g, "").slice(0, 6); //TODO: make this settable
          this.$refs["eartag-input-field"].value = this.currentEartagFull;
        } else {
          this.currentEartagFull = this.$refs["eartag-input-field"].value;
          this.$refs["eartag-input-field"].value = this.currentEartagFull;
        }

        if (tagCaptureState === "ready_for_manual_input" && this.currentEartagFull.length > 0) {
          this.$store.commit("tagCapture/SET_STATE", "manual_input_received");
        } else if (tagCaptureState === "manual_input_received" && this.currentEartagFull.length === 0) {
          this.$store.commit("tagCapture/SET_STATE", "ready_for_manual_input");
        }

        this.$store.commit("tagCapture/SET_TAG_ID", this.currentEartagFull);
      }

      if (this.$refs["eartag-input-field"].value === "") {
        //this.isManualTagEntry = false;
      }
    },
    onEartagInputKeyDown: function (event) {
      // if (event.key === "Enter") {
      //   this.$refs["eartag-input-field"]blur();
      // }
    },
    addMass: function (mass) {
      //const MIN_MASS = -0.1;
      const MIN_MASS = 100;
      const MAX_MASS = 1500;

      const SAMPLE_SIZE = 20.0;
      const EPSILON = 50;

      this.rawMass = mass;
      this.scaleActivityToggle = !this.scaleActivityToggle;

      //if (!this.isMassCaptured) {
      if (this.captureMassState !== "captured") {
        this.massQueue.push(mass);
        this.currentMass = mass;
      }

      if (this.massQueue.length > 50) {
        this.massQueue.shift();
      }

      let data = new Array();

      this.massQueue.forEach((mass, index) => {
        //lamda must be used so that the right 'this' is referenced
        data.push([index + (50 - this.massQueue.length), mass]);
      });

      //if (this.isCapturingMass) {
      if (this.captureMassState === "capturing") {
        this.captureMassQueue.push(+mass);
        if (this.captureMassQueue.length > SAMPLE_SIZE) {
          this.captureMassQueue.shift();
        }
        if (this.captureMassQueue.length == SAMPLE_SIZE) {
          // let sum = this.captureMassQueue.reduce((x, y) => x + y, 0)
          // let avg = sum / SAMPLE_SIZE
          let avg = lodash.mean(this.captureMassQueue);

          let total = 0.0;
          this.captureMassQueue.forEach((x) => {
            total += Math.abs(x - avg);
          });

          //this.isMassCaptured = total < EPSILON;
          if (total < EPSILON && +mass > MIN_MASS && +mass < MAX_MASS) {
            this.captureMassState = "captured";
            //this.captureButtonState = "success"
            this.capturedMass = avg;
            this.captureMassCancelTimerDebounce.cancel();
          }
        }
      }
      drawChart(data, this.captureMassState === "captured");

      let scaleActivityCircle = this.$refs["scaleActivityCircle"];
      if (scaleActivityCircle) scaleActivityCircle.attributes.fill.nodeValue = this.scaleActivityColour;

      let rawScaleActivityCircle = this.$refs["rawScaleActivityCircle"];
      if (rawScaleActivityCircle) rawScaleActivityCircle.attributes.fill.nodeValue = this.rawScaleActivityColour;
    },
    addSigmoidMass: function () {
      this.addMass(sigmoid(timer - 4) * 500);
      timer += 0.1 * 2;
      if (!stopAddingMass) {
        lodash.debounce(this.addSigmoidMass, 100)();
      }
    },
    checkHardwareStatus: function () {
      if (this.diagHardwareStatus !== "connected") this.diagHardwareStatus = "connecting";

      if (this.diag3rdPartyStatus !== "connected") this.diag3rdPartyStatus = "connecting";

      //if (this.diagOnsiteServerStatus !== "connected") this.diagOnsiteServerStatus = "connecting";

      if (this.diagCloudStatus !== "connected") this.diagCloudStatus = "connecting";

      if (this.diagScaleStatus !== "connected") this.diagScaleStatus = "connecting";

      if (this.diagRfidStatus !== "connected") this.diagRfidStatus = "connecting";

      if (this.diagAllflexStatus !== "connected") this.diagAllflexStatus = "connecting";
      //TODO: Environment check for http and https
      let url = "https://" + this.server.address + ":" + this.server.portHttp + configData.restApiCalls.hardwareStatus;
      console.log(url);
      axios.defaults.timeout = 500;
      axios
        .get(url)
        .then((response) => {
          this.diagHardwareStatus = "connected";

          let thirdPartyTemp = lodash.find(response.data, function (o) {
            return o.Name === "Scale";
          });
          this.diag3rdPartyStatus = thirdPartyTemp && thirdPartyTemp.Status === true ? "connected" : "disconnected";

          // let serverTemp = lodash.find(response.data, function(o) {
          //   return o.Name === "Server";
          // });
          // this.diagOnsiteServerStatus = serverTemp && serverTemp.Status === true ? "connected" : "disconnected";

          let cloudTemp = lodash.find(response.data, function (o) {
            return o.Name === "Cloud";
          });
          this.diagCloudStatus = cloudTemp && cloudTemp.Status === true ? "connected" : "disconnected";

          let scaleTemp = lodash.find(response.data, function (o) {
            return o.Name === "Scale";
          });

          this.diagScaleStatus = scaleTemp && scaleTemp.Status === true ? "connected" : "disconnected";

          if (!scaleTemp && this.massCaptureEnabled) {
            this.$store.commit("SET_MASS_CAPTURE_ENABLED", false);
          } else if (scaleTemp && !this.massCaptureEnabled) {
            this.$store.commit("SET_MASS_CAPTURE_ENABLED", true);
          }
          if (scaleTemp && !scaleTemp.Status) {
            this.$store.commit("SET_MASS_CAPTURE_ENABLED", false);
          }

          let rfidTemp = lodash.find(response.data, function (o) {
            return o.Name === "Rfid";
          });
          this.diagRfidStatus = rfidTemp && rfidTemp.Status === true ? "connected" : "disconnected";

          let allflexTemp = lodash.find(response.data, function (o) {
            return o.Name === "Allflex";
          });
          if (!allflexTemp && this.allflexEnabled) {
            this.$store.commit("SET_ALLFLEX_ENABLED", false);
          } else if (allflexTemp && !this.allflexEnabled) {
            this.$store.commit("SET_ALLFLEX_ENABLED", true);
          }

          this.diagAllflexStatus = allflexTemp && allflexTemp.Status === true ? "connected" : "disconnected";
        })
        .catch((error) => {
          this.diagHardwareStatus = "disconnected";
          this.diag3rdPartyStatus = "disconnected";
          //this.diagOnsiteServerStatus = "disconnected";
          this.diagCloudStatus = "disconnected";
          this.diagScaleStatus = "disconnected";
          this.diagRfidStatus = "disconnected";
          this.diagAllflexStatus = "disconnected";
        })
        .finally(() => {
          lodash.debounce(this.checkHardwareStatus, 5000)();
        });
    },
    getFeedlotInfo: function () {
      console.log("getting feedlot info");
      let app = this;
      axios
        .get(configData.restApiCalls.feedlots, { timeout: 5000 })
        .then((response) => {
          //console.log(response);
          app.$store.commit("SET_FEEDLOT_INFO", response.data);
          console.log("feedlot info successfully retrieved");
          console.log("getting colour info");
          axios
            .get(configData.restApiCalls.colours, { timeout: 5000 })
            .then((response) => {
              app.$store.commit("SET_COLOUR_INFO", response.data);
              console.log("colour info successfully retrieved");
              console.log("getting sgln");
              //TODO: environment check for http and https:
              let url = "https://" + this.server.address + ":" + this.server.portHttp + configData.restApiCalls.sgln;
              axios
                .get(url, { timeout: 5000 })
                .then((response) => {
                  console.log("sgln: " + response.data);
                  app.$store.commit("SET_SGLN", response.data);
                })
                .catch((error) => {
                  console.log("sgln error: " + error);
                });
            })
            .catch((error) => {
              console.log("colour info error: " + error);
            });
        })
        .catch((error) => {
          console.log("feedlot info error: " + error);
        })
        .finally(() => {});
    },
    checkReceived3rdParty: function () {
      if (!this.currentBatch) {
        return;
      }
      //call api for upload queue
      //TODO: Environment check for http and https
      axios
        .get("https://" + this.server.address + ":" + this.server.portHttp + configData.restApiCalls.checkReceived3rdParty + "/" + this.currentBatch.batchNumber, { timeout: 5000 })
        .then((response) => {
          //extract uuids
          let uuids = new Array(); //uuids of animals that are still to be sent to 3rd party

          response.data.forEach(function (o) {
            uuids.push(o.Uuid);
          });

          this.currentBatch.animals.forEach((a) => {
            if (
              !lodash.find(uuids, (uuid) => {
                return uuid === a.animalUuid;
              }) //if animal not in list of uuids then it has been received by the 3rd party server
            ) {
              if (a.uploadStatus !== "receivedBy3rdParty") {
                a.uploadStatus = "receivedBy3rdParty";
                this.$store.commit("UPDATE_ANIMAL_UPLOAD_STATUS", {
                  animalUuid: a.animalUuid,
                  uploadStatus: "receivedBy3rdParty",
                });
              }
            }
          });
        })
        .catch((error) => {})
        .finally(() => {
          lodash.debounce(this.checkReceived3rdParty, 5000)();
        });
    },
    connectScaleWebSocket: function () {
      if (!this.$store.state.massCaptureEnabled) {
        return;
      }
      //TODO: Environment detection for ws and wss
      let ws = new WebSocket("wss://" + this.server.address + ":" + this.server.portScaleWebSocket);
      let app = this;
      (ws.onerror = function (event) {
        //console.log('123');
        console.log("scale websocket error");
      }),
        (ws.onmessage = (message) => {
          app.addMass(message.data);
        });
      ws.onclose = () => {
        //TODO: Environment detection for ws and wss
        // Try to reconnect in 5 seconds
        lodash.debounce(() => {
          app.connectScaleWebSocket("wss://" + this.server.address + ":" + this.server.portScaleWebSocket);
        }, 5000)();
      };
    },
    connectAllflexWebSocket: function () {
      if (!this.allflexEnabled) return;
      //TODO: Environment detection for ws and wss
      let ws = new WebSocket("wss://" + this.server.address + ":" + this.server.portAllflexWebSocket);
      let app = this;
      (ws.onerror = function (event) {
        console.log("allflex websocket error");
      }),
        (ws.onmessage = (message) => {
          //addMass(message.data);
          console.log("Allflex: " + message.data);
          //console.log(app.selectedTagType);

          app.recentAllflexScan = message.data;
          if (app.recentAllflexScanDebounced) {
            app.recentAllflexScanDebounced.cancel();
          }
          app.recentAllflexScanDebounced = lodash.debounce(() => {
            app.recentAllflexScan = "";
          }, 2000);
          app.recentAllflexScanDebounced();

          //app.scanTagState = "scanned";
          //app.currentEartag = message.data.slice(-15);
          if (this.$store.state.tagCapture.tagCaptureState === "empty_tag") {
            this.$store.commit("tagCapture/SET_TAG_ID", message.data);
            this.$store.commit("tagCapture/SET_STATE", "allflex_tag_scanned");
            this.$store.commit("tagCapture/SET_TAG_TYPE", "allflex");
            //this.currentEartagFull = this.$store.state.tagCapture.tagId;
            this.currentEartagFull = this.getVisualFriendlyTag(this.$store.state.tagCapture.tagId, this.$store.state.tagCapture.tagType);
          }
          //app.statusMessage = message.data;
        });
      ws.onclose = () => {
        // Try to reconnect in 5 seconds
        //TODO: Environment detection for ws and wss
        lodash.debounce(() => {
          app.connectAllflexWebSocket("wss://" + this.server.address + ":" + this.server.portAllflexWebSocket);
        }, 5000)();
      };
    },
  },
  computed: {
    server: function () {
      return {
        //address: configData.server.address,
        address: this.$store.state.hardwareAddress,
        portHttp: configData.server.portHttp,
        portScaleWebSocket: configData.server.portScaleWebSocket,
        portAllflexWebSocket: configData.server.portAllflexWebSocket,
      };
    },
    isLandscape: function () {
      return this.windowSize.x >= this.windowSize.y;
    },
    allflexEnabled: function () {
      return this.$store.state.allflexEnabled;
    },
    massCaptureEnabled: function () {
      return this.$store.state.massCaptureEnabled;
    },
    tagTypeVisualFriendly: function () {
      return this.getVisualFriendlyTagType(this.$store.state.tagCapture.tagType);
    },
    isManualDialogVisible: function () {
      return this.$store.state.tagCapture.tagCaptureState === "tag_type_selection";
    },
    isColourSelectionDialogVisible: function () {
      return this.$store.state.tagCapture.tagCaptureState === "tag_colour_selection";
    },
    isManualTagEntry: function () {
      let tagCaptureState = this.$store.state.tagCapture.tagCaptureState;
      return tagCaptureState === "ready_for_manual_input" || tagCaptureState === "manual_input_received";
    },
    currentBatch: function () {
      return this.$store.state.currentBatch;
    },
    currentBatchDescription: function () {
      return this.currentBatch ? this.currentBatch.description : "";
    },
    noTagButtonState: function () {
      let tagCaptureState = this.$store.state.tagCapture.tagCaptureState;
      if (this.currentBatch && this.currentBatch.completedQuantity >= this.currentBatch.totalQuantity) {
        return "disabled";
      } else if (tagCaptureState === "empty_tag") {
        return "ready";
      } else {
        return "disabled";
      }
    },
    clearButtonState: function () {
      if (this.currentEartagFull && this.currentEartagFull.length > 0) {
        return "ready";
      } else {
        return "disabled";
      }
    },
    manualButtonState: function () {
      let tagCaptureState = this.$store.state.tagCapture.tagCaptureState;
      if (this.currentBatch && this.currentBatch.completedQuantity >= this.currentBatch.totalQuantity) {
        return "disabled";
      } else if (tagCaptureState === "empty_tag") {
        return "ready";
      } else {
        return "disabled";
      }
    },
    captureButtonState: function () {
      //"ready" | "busy" | "success" | "error" | "disabled"
      if (!this.$store.state.massCaptureEnabled) {
        return "disabled";
      } else if (this.diagScaleStatus !== "connected") {
        return "disabled";
      } else if (this.currentBatch && this.currentBatch.completedQuantity >= this.currentBatch.totalQuantity) {
        return "disabled";
      }
      switch (this.captureMassState) {
        case "notCapturing":
          return "ready";
        case "capturing":
          return "busy";
        case "captured":
          return "success";
        case "failed":
          return "error";
      }
    },
    scanButtonState: function () {
      //"ready" | "busy" | "success" | "error" | "disabled"
      if (this.diagRfidStatus !== "connected") {
        return "disabled";
      } else if (this.currentBatch && this.currentBatch.completedQuantity >= this.currentBatch.totalQuantity) {
        return "disabled";
      }
      let tagCaptureState = this.$store.state.tagCapture.tagCaptureState;
      let isError = this.$store.state.tagCapture.error;
      if (tagCaptureState === "empty_tag") {
        if (isError) {
          return "error";
        } else {
          return "ready";
        }
      } else if (tagCaptureState === "scanning_dimssa_tag") {
        return "busy";
      } else if (tagCaptureState === "success_dimssa_tag_scanned" || tagCaptureState === "allflex_tag_scanned") {
        return "success";
      } else if (tagCaptureState === "no_tag") {
        return "disabled";
      } else {
        return "ready";
      }
    },
    saveButtonState: function () {
      let tagCaptureState = this.$store.state.tagCapture.tagCaptureState;
      let tagCaptureReadyToSave = ["success_dimssa_tag_scanned", "allflex_tag_scanned", "no_tag", "manual_input_received"].includes(tagCaptureState);
      //TODO CONFIG: make this settable.
      if (this.$store.state.config.isMassCaptureRequiredForSaving) {
        if (this.currentBatch && tagCaptureReadyToSave && (this.captureMassState === "captured" || !this.$store.state.massCaptureEnabled)) {
          //this mode only allows save if mass capture was successful
          return "ready";
        }
      } else {
        if (this.currentBatch && tagCaptureReadyToSave) {
          //this mode doesn't care for mass capture
          return "ready";
        }
      }

      return "disabled";
    },
    newBatchButtonState: function () {
      if (this.currentBatch) {
        //check if any animals have not been uploaded yet
        let unsentAnimals = this.currentBatch.animals.filter((animal) => animal.uploadStatus === "sending" || animal.uploadStatus === "failedToSend");
        if (unsentAnimals.length > 0) {
          return "disabled";
        }
      }
      return "ready";
    },
    animalTableHeaders: function () {
      let ret = [];

      ret.push({
        text: "Number",
        align: "center",
        sortable: false,
        value: "number",
      });
      ret.push({
        text: "Eartag/ID",
        align: "center",
        sortable: false,
        value: "eartagId",
      });
      ret.push({
        text: "Feedlot",
        align: "center",
        sortable: false,
        value: "feedlot",
      });
      if (this.$store.state.massCaptureEnabled) {
        ret.push({
          text: "Live Mass",
          align: "center",
          sortable: false,
          value: "liveMass",
        });
      }
      ret.push({
        text: "Status",
        align: "center",
        sortable: false,
        value: "status",
      });

      return ret;
    },
    animalTableItems: function () {
      let ret = [];
      if (!this.currentBatch) return ret;

      this.currentBatch.animals.forEach((animalRecord) => {
        //console.log(this.$store.getters["getTagColour"](animalRecord.tagId).Name);
        ret.push({
          number: animalRecord.numberInQueue,
          eartagId: this.getVisualFriendlyTag(animalRecord.tagId, animalRecord.tagType),
          feedlot: animalRecord.tagType === "dimssa" ? this.getFeedlotNameFromSgtin(animalRecord.tagId) : "NA",
          tagType: this.getVisualFriendlyTagType(animalRecord.tagType),
          liveMass: animalRecord.mass > 0 ? animalRecord.mass : "NA",
          status: animalRecord.uploadStatus,
          colour: this.$store.getters["getTagColour"](animalRecord.tagId) ? this.$store.getters["getTagColour"](animalRecord.tagId).Name : undefined,
        });
      });
      return ret.reverse();
    },
    scaleActivityColour: function () {
      if (this.captureMassState === "captured" || !this.scaleActivityToggle) return "lime";
      else if (this.scaleActivityToggle) return "white";
    },
    rawScaleActivityColour: function () {
      //scale activity still shows even if mass has been captured
      if (!this.scaleActivityToggle) return "lime";
      else if (this.scaleActivityToggle) return "white";
    },
  },
  watch: {
    allflexEnabled: function (newValue, oldValue) {
      if (newValue) {
        this.connectAllflexWebSocket();
      }
    },
    massCaptureEnabled: function (newValue, oldValue) {
      if (newValue) {
        console.log("massCaptureEnabled: initializeChart");
        this.connectScaleWebSocket();
        initializeChart();
      }
    },
  },
  mounted: function () {
    this.$vuetify.theme.dark = this.$store.getters["dark"]();
    initializeChart();
   // this.$store.commit("SET_HARDWARE_ADDRESS", "pi.gigalot.systems");
    this.checkHardwareStatus();
    this.checkReceived3rdParty();
    this.getFeedlotInfo();

    //this.$store.dispatch("initializeDatabase");
    this.$store.dispatch("restoreSession");

    this.currentEartagFull = this.getVisualFriendlyTag(this.$store.state.tagCapture.tagId, this.$store.state.tagCapture.tagType);
    this.connectScaleWebSocket();
    this.connectAllflexWebSocket();
  },
};

export default vmApp;
</script>

