import { Module, ActionContext } from "vuex";

class ConfigState {
  //If true then mass must be captured before the save button enables
  isMassCaptureRequiredForSaving: boolean = true;
  //if true then "No Tag" must be typed in to confirm no tag.
  mustNoTagBeTyped: boolean = true;
}

class Config implements Module<ConfigState, any> {
  namespaced: boolean = true;
  state: ConfigState = new ConfigState();
  mutations = {
    //mutation(state: State, payload: any) {}
  };
  actions = {
    //action(context: ActionContext<ModuleState, any>,) {}
  };
  getters = {
    //getter(state: ModuleState, getters: any, rootState: any, rootGetters: any) {}
  };
}

const config: Config = new Config();

export default config;
