
























import { Component, Vue, Watch } from "vue-property-decorator";

import { configData } from "../config";
import axios from "axios";

@Component({
  components: {},
})
export default class Settings extends Vue {
  powerLevel = 30;
  isDarkMode = true;
  server = {
    orcaAddress: this.$store.state.orcaAddress,
    address: this.$store.state.hardwareAddress,
    portHttp: configData.server.portHttp,
    portOrcaWebSocket: configData.server.portOrcaWebSocket,
  };

  setPowerLevel() {
    let app = this;
    console.log(`Setting power level to ${this.powerLevel}`);
    let powerLevel = { power: this.powerLevel };
    axios
      .post("https://" + app.server.orcaAddress + ":" + app.server.portHttp + "/settings", powerLevel)
      .then((response) => {
        //set power level to response of reader power level
        console.log("response: " + response.data.power);
        this.powerLevel = response.data.power;
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  }

  getPowerLevel() {
    let app = this;
    axios.defaults.timeout = 10000;
    //lamda function has to be used here, using function() syntax results in a different 'this'
    //TODO: Environment check for http and https
    axios
      .get("https://" + app.server.orcaAddress + ":" + app.server.portHttp + "/settings")
      .then((response) => {
        //set power level to response of reader power level
        console.log("response: " + response.data.power);
        this.powerLevel = response.data.power;
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  }

  setHardwareAddress(address: string) {
    console.log(`Setting hardware address to : ${address}`);
    this.$store.commit("SET_HARDWARE_ADDRESS", address);
  }

  mounted() {
    this.getPowerLevel();
    this.$vuetify.theme.dark = this.$store.getters["dark"]();
  }

  toggleLightDarkMode() {
    this.$store.commit("lightDarkMode", this.$store.state.lightDarkMode === "light" ? "dark" : "light");
    this.$vuetify.theme.dark = this.$store.getters["dark"]();
  }
}
